@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';

.wrapper {
  border: 0px !important;
  border-radius: 6px !important;
  margin: 15px 15px 10px 10px !important;
  padding: 20px 20px 20px 20px !important;
  background-color: @white !important;
  box-shadow: 0px 0px 10px 0.5px @border-grey !important;
}

.title {
  padding: 0;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: @dark-grey !important;
  border-bottom: 1px solid @border-grey !important;
  line-height: 1.3 !important;
  margin-bottom: 0px !important;
}

.hidden {
  display: none;
  border: 0 !important;
}
