@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';

.updateCSV {
  display: flex;
  align-items: center;

  input {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
    visibility: hidden;
  }

  label {
    min-width: 110px;
    text-align: center;

    position: relative;
    cursor: pointer;
    padding: 10px;
    background-color: @ref-color-lightBlue;
    color: #FFFFFF;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;

    &:hover {
      background-color: @ref-color-underline;
      text-decoration: underline;
    }
  }

  span {
    margin-left: 10px;
    opacity: 1;
    color: @ref-color-blackLight;
    font-size: 13.5px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19px;
  }
}


