@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import "common/components/site.variables";

// Define the variable for width
@sidebarMaxWidth: 280px;
@sidebarMinWidth: 20px;

.sidebarAside {
  background: @light-grey;
  position: relative;
  transition: all 0ms;
  width: 100%;
  min-width: 350px;
  padding: 20px 10px 20px 14px;
  border-right: 1px solid @border-grey;

  .sidebarAsideInner {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
}

.sidebarAsideClosed {
  max-width: @sidebarMinWidth !important;
  min-width: @sidebarMinWidth;
  width: @sidebarMinWidth;
  background: @light-grey !important;

  @media (min-width: @breakpoint-tablet) {
    padding: 10px;
  }

  .sidebarAsideInner {
    visibility: hidden;
    overflow: hidden;
    width: 0;
    height: 0;
    opacity: 0;
  }

  .sidebarAsideButton {
    right: -20px;
  }

}

.sidebarAsideButton {
  position: absolute;
  line-height: 60px;
  bottom: 20px;
  right: -20px;
  border: 0;
  border-radius: 0 6px 6px 0;
  background: @blue;
  color: @white;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: color 300ms;
  z-index: 2;

  &:hover {
    color: @light-grey;
  }

  i {
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    font-size: 20px;
  }
}

.sidebarContent {
  width: 100%;
  min-width: 320px;
  height: auto;
  position: relative;
  z-index: 1;
  overflow-y: auto;
  padding: 10px 14px 20px 10px;

  @media (min-width: @breakpoint-tablet) {
    padding: 10px 40px 40px 20px;

  }

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.sidebarContentFullWidth {
  padding: 0;
}
