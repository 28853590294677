@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';

.footer {
  position: relative;
  background-color: #fff;
}

.footerTop {
  background: @ref-color-active;
  padding: 4.375rem 0rem 2.1875rem;
  position: relative;

  &:before {
    content: '';
    width: 100vw;
    height: 100%;
    display: block;
    background: @ref-color-active;
    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
  }
}

.footerTop_row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: column;

  @media (min-width: @breakpoint-tablet) {
    flex-direction: row;
  }
}

.footerTop_col {
  padding-right: 15px;
  padding-left: 15px;
  max-width: 160px;
  margin: auto;

  &:not(:last-child) {
    padding-bottom: 15px;
  }


  @media (min-width: @breakpoint-tablet) {
    max-width: 120px;
    margin: initial;
    &:not(:last-child) {
      padding-bottom: 0;
    }
  }

  @media (min-width: @breakpoint-tablet-horizontal) {
    max-width: 160px;
  }
  @media (min-width: @breakpoint-desktop) {
    max-width: 190px;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.footerTop_title {
  color: @ref-color-grey;
  font-size: 15px;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 13px;
  letter-spacing: 0;
  font-weight: 700;
}

.footerBottom {
  position: relative;
  padding: 1rem 0;
}

.footerWrapper {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  flex-direction: column;

  @media (min-width: @breakpoint-tablet) {
    flex-direction: row;
  }
}

.footerSearch {
  max-width: 825px;
  width: 100%;

  .footerSearch_Input {
    width: 100%;

    input {
      padding: 0.625rem 0.9375rem;
      border: 0px;
      margin: 0px 0.5em 0 0;
      border-radius: 0;
      font-size: 15px;
      font-weight: normal;
      line-height: 22px;
      background-color: @ref-color-lightWhite;
      box-sizing: border-box;
      color: #000000;

      &:focus {
        background-color: @ref-color-lightWhite;
      }
    }

    input::placeholder {
      color: rgb(117, 117, 117);
    }

    button {
      transition: background-color 300ms;
      letter-spacing: 1px;
      text-transform: uppercase;
      line-height: 1;
      text-align: center;
      padding: 15px 23px 15px 23px;
      font-size: 13px;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      background-color: @ref-color-red;
      color: @white;
      font-weight: 400;

      &:hover,
      &:focus {
        background-color: @ref-color-redDark;
        color: @white;
        text-decoration: underline;
      }
    }
  }

  .list {
    list-style: none;
    margin: 0 0 14px;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    @media (min-width: @breakpoint-tablet) {
      flex-direction: row;
    }

    a {
      display: block;
      color: @ref-color-active;
      line-height: 4rem;
      padding: 0 1.5rem;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.footerLogo {
  max-width: 100px;
  max-height: 45px;
  display: inline-block;
  color: @ref-color-active;

  a {
    color: inherit;
  }
}

.footerCopyrights {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    padding: 0 0 0 1rem;
  }
}

.softwareVersion {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  @media (min-width: @breakpoint-tablet-horizontal) {
    position: absolute;
    min-height: 36px;
    padding: 0;
    bottom: 0;
    left: 0;
  }
}

