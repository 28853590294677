@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../../../../../../common/components/site.variables';

.fileUpload {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;

}

.dateFormat {
  display: flex;
  align-items: center;

  .dateFormatLabel {
    width: auto;
    white-space: nowrap;
    margin: 0 12px 0 0;
  }

  .dateFormatInput {
    width: auto;
    flex-grow: 1;

    & > * {
      width: 100%;
    }
  }

}

.scrollContainer {
  max-height: 300px;
  height: 100%;
  overflow-x: auto;
  position: relative;

  &::-webkit-scrollbar-thumb {
    padding-top: 26px;
    border-radius: 2px;
    background-color: @ref-color-underline;
  }

  &::-webkit-scrollbar-track {
    width: 10px;
    background-color: @white;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, .3);
  }
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-end;

  padding-top: 25px;
  border-top: 1px solid @ref-color-underline;
}

.table {
  border: none !important;

  thead {
    background: @white !important;
    background: transparent;
    position: sticky;
    top: 0;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: @ref-color-borderGrey;
      display: block;
      bottom: 0;
      left: 0;
    }
  }

  th {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: left;
    padding: 5px 20px !important;
    background: @white !important;
  }

  th, td {
    border: none !important;
  }

  tr {
    border-bottom: 1px solid @ref-color-borderTable;
  }


}


