@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';

.movableItem {

  & + .movableItem {
    margin-top: 10px;
  }

  .renameField {
    position: absolute;
    width: 100%;
    height: 30px;
    right: 0;
    padding-left: 30px;
    z-index: 3;
    display: flex;

    input {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: unset;
      min-height: 26px;
      padding: 5px 10px;
      font-size: 13.5px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 15px;
      background-color: @light-grey;
      color: @dark-grey;

      &:focus-visible {
        outline: 1px dotted @ref-color-underline;
      }
    }

    button {
      color: @white;
      background-color: @blue;
      font-size: 13.5px;
      font-weight: 700;
      letter-spacing: 0px;
      text-align: center;
      line-height: 19px;
      padding: 4px 20px;
      border: none;
      border-radius: 6px;
    }
  }

  [class^="accordionTitleText"] {
    padding-left: 42px !important;
  }
}
