@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../../../../../common/components/site.variables';

.container {
  display: flex;
  justify-content: flex-end;
  border-radius: 6px !important;

  & > div {
    height: auto;
    width: 100%;
    border-radius: 6px !important;

    @media (min-width: @breakpoint-tablet) {
      width: 50%;
    }

  }
}

.form {
  background: @white !important;
  padding: 50px 50px 30px 20px !important;
  position: relative;
  border-radius: 6px !important;

  @media (min-width: @breakpoint-tablet) {
    padding: 66px 40px 20px 20px;
  }

  @media (min-width: @breakpoint-tablet-horizontal) {
    padding: 66px 72px 20px 20px;
  }

}

.image {
  display: none;
  padding: 50px 20px 50px 20px;

  @media (min-width: @breakpoint-tablet) {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.title {
  border-bottom: 1px solid @border-grey;
  padding-bottom: 6px;
  margin-bottom: 30px;
  text-transform: inherit;
  font-size: 22px;
  font-weight: 600;
}

.form .field label.label {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  line-height: 25px;
}

.form .field textarea {
  resize: none;
  height: 60px;
}


.mandatory {
  border-bottom: 1px solid @border-grey;
  padding-bottom: 6px;
  margin-top: 40px;
  margin-bottom: 22px;
}

.buttons {
  display: flex;
  gap: 20px;

  button:nth-child(2) {
    flex: 1;
  }
}
