@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';

// Define the variable for width
@sidebarMaxWidth: 280px;
@sidebarMinWidth: 50px;

.sidebarMenu {
  display: flex;
  background: @light-grey;
  position: relative;
  z-index: 100;
  width: @sidebarMinWidth;
  min-width: @sidebarMinWidth;
  height: 100%;

  .sidebarMenuWrapper {
    display: flex;
    flex-grow: 1;
    background: @white;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transition: width 50ms;
    width: @sidebarMinWidth;
    overflow: hidden;
    // border-right: 1px solid @border-grey;
    // box-shadow: 0px 0px 10px 0.5px @border-grey;

    &.open {
      width: @sidebarMaxWidth;
    }

  }

  .sidebarMenuList {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: @sidebarMaxWidth;
    list-style: none;
    color: @blue;
    margin: 0;
    padding: 20px 20px 20px 0;

    overflow-y: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  .item {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.3;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    & + .item.title {
      margin-top: 20px;
    }

    &:not(.title):hover {
      cursor: pointer;
      // text-decoration: underline;
    }
  }

  .active {
    &.item .description {
      font-weight: 700;
      text-decoration: underline;
    }

    &:before {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      position: absolute;
      left: 7px;
      top: calc(50% - 2px);
      display: block;
      background: @blue;
    }

    &.title:before {
      left: 4px;
    }
  }

  .title {
    margin-bottom: 20px;
    pointer-events: none;
  }

  .disabled {
    color: @disabled;
    pointer-events: none;
    cursor: unset;
  }

  .link {
    display: flex;
    align-items: center;
    color: inherit;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    font-size: 16px;
    transform: scale(1.2);
    padding-bottom: 5px;
  }

  .description {
    padding-left: 18px;
    font-size: 15px;
    font-style: normal;
    letter-spacing: 0px;
    line-height: 1;
  }

  .item.title {

    .icon {
      transform: scale(1.2);
      padding-bottom: 3px;
      display: none;
    }

    .description {
      text-transform: uppercase;
      font-size: 25px;
      line-height: 1.3;
      pointer-events: none;
      display: none;
    }
  }
}


