@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';

.movableList {
  font-size: 12px;
  font-weight: 400;
  max-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 0 0 0 !important;
  border-bottom: 1px solid @ref-color-underline;
  border-top: 1px solid @ref-color-underline;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: @ref-box-shadow;
    background: @ref-color-borderTable;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: @ref-color-underline;
    border-radius: 2px;
  }
}


.movableItem {
  align-items: center;
  display: flex;
  background-color: @white;

  & + .movableItem {
    margin-top: 1px;
  }

  .movableItemInner {
    width: 100%;
    display: flex;
    padding: 0 0 5px 32px;
  }

  &:not(:last-child) .movableItemInner {
    border-bottom: 1px solid @lightGrey;
    margin-top: 1px;
  }
}

.movableButton {
  cursor: move;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  color: @dark-grey;
  background-color: @white !important;

  &:before {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}


