@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';

.infoTitle {
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  padding: 0 0 2px 0;
  margin: 0 0 16px;
  height: 27px;
  border-bottom: 1px solid @ref-color-underline;
  display: flex;
  gap: 10px;
  justify-content: space-between;


  .secondary {
    position: relative;
    background: transparent;
    color: #333333;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: left;
    padding: 0 0 2px 0;
    border-bottom: none;
    display: flex;
    align-items: baseline;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -3px;
      height: 3px;
      display: block;
      z-index: 2;
      width: 100%;
      background-color: #333333;
    }
  }


  i {
    color: @ref-color-underline;
    width: 16px;
    height: 16px;
    font-size: 15px;
    margin-right: 5px;
  }

  .actions {
    display: flex;
    gap: 10px;

    i {
      display: flex;
      align-items: center;
    }

    span,
    button {
      text-transform: none;
      display: flex;
      align-items: center;
      padding: 0 1px 0 0;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      background: transparent !important;
      border: none;
      letter-spacing: 0px;
      text-align: left;
    }

    button {
      cursor: pointer;
      color: @ref-color-red;

      &:hover {
        color: @ref-color-underline;
      }
    }
  }


}

//* + .infoTitle {
//  margin-top: 16px;
//}
