@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import 'common/components/site.variables';

.stressPeriod {
  margin: 20px 0;

  .tableWrapper {
    padding-bottom: 10px;
    border-bottom: 1px solid @border-grey;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    table.table {
      min-width: 600px;
      width: 100%;
      border-spacing: 0;
      background: none;
      border: none;
      border-collapse: collapse;
      margin: 0;

      .tableHeader {
        display: block;
        background: #dbe4ee;
        padding-right: 20px;
        color: @dark-grey;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        border-radius: 6px;

        th {
          background: transparent;
        }

        th:first-child {
          display: flex;
          justify-content: center;
        }

        i {
          color: @ref-color-underline;
          width: 16px;
          height: 16px;
          font-size: 15px;
          margin-right: 5px;
        }
      }

      .tableBody {
        display: block;
        height: auto;
        margin: 10px 10px 0 0;
        max-height: 800px;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;

        &::-webkit-scrollbar-thumb {
          padding-top: 26px;
          border-radius: 2px;
          background-color: @ref-color-underline;
          cursor: pointer;
        }

        &::-webkit-scrollbar-track {
          width: 10px;
          background-color: @white;
          cursor: pointer;
          -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, .3);
        }
      }

      .tableBody td,
      .tableHeader th {
        display: flex;
        align-items: center;
        padding: 0 10px;
        border: none;
      }

      .tableBody .tableRow {
        padding-bottom: 5px;

        &:last-child {
          height: 25px;
          padding-bottom: 0;
        }
      }

      .tableRow {
        display: flex;
        height: 35px;

        //width: 10%
        td:first-child,
        th:first-child {
          width: 10%;
          padding-left: 0;

          span {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: center;
            background: #dbe4ee;
            border-radius: 6px;
          }
        }

        //width: 25%
        td:nth-child(2),
        th:nth-child(2) {
          width: 25%;

          div {
            width: 100%;
          }
        }

        //width: 20% * 3
        td:nth-child(3),
        th:nth-child(3),
        td:nth-child(4),
        th:nth-child(4),
        td:nth-child(5),
        th:nth-child(5) {
          width: 20%;
        }

        td:last-child,
        th:last-child {
          width: 5%;
          padding: 0;

          button {
            background: transparent;
            color: @dark-grey;
            margin-left: 0;
            padding: 0;
          }
        }
      }
    }
  }

  .buttonsGroup {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}
