@import "site.variables";

body {
  color: #4c4c4c;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// helpers
.borderBottom {
  border-bottom: 1px solid @ref-color-underline;
  padding-bottom: 14px !important;
}

.gepReset {
  gap: 0 !important;
}

.dateInputWrapper {
  transition: all 0.3s;

  .divider {
    display: flex;
    align-items: center;
  }

  .dateIcon {
    margin: 0 0 0 5px;
    height: 100%;
  }

  &.datePicker {

    @media (min-width: @breakpoint-mobile-horizontal) {
      .rowTwoColumns:first-child {
        .react-datepicker-popper {
          padding-left: 55px;
        }
      }

      .rowTwoColumns:last-child {
        .react-datepicker-popper {
          padding-right: 55px;
        }
      }
    }

  }
}

.hidden {
  display: none;
}

.fullWidth {
  width: 100%;
}

.scrollWrapper-Y {
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.scrollWrapper-X {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  //width
  &::-webkit-scrollbar {
    width: 4px;
  }

  // Track
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  // Handle
  &::-webkit-scrollbar-thumb {
    background: @blue;
  }

  // Handle on hover
  &::-webkit-scrollbar-thumb:hover {
    background: #0277CC;
  }
}

.scrollableTable {
  max-height: 300px;
  height: 100%;
  overflow-x: auto;
  position: relative;

  &::-webkit-scrollbar-thumb {
    padding-top: 26px;
    border-radius: 2px;
    background-color: @ref-color-underline;
  }

  &::-webkit-scrollbar-track {
    width: 10px;
    background-color: @white;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, .3);
  }

  table.table {
    border: none !important;

    thead {
      background: @white !important;
      position: sticky;
      top: 0;
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background: @ref-color-borderGrey;
        display: block;
        bottom: 0;
        left: 0;
      }
    }

    thead tr th {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0px;
      text-align: left;
      padding: 5px 20px;
      background: @white;

    }

    tbody tr td,
    thead tr th {
      border: none;
    }

    tr {
      border-bottom: 1px solid @ref-color-borderTable;
    }
  }
}


