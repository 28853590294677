@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';

.searchWrapper {
  display: flex;
  align-items: stretch;
  width: 100%;

  .button,
  .search {
    display: flex;
    height: auto;
    align-items: center;
  }

  .button {
    font-size: 16px !important;
    margin-right: 10px;
    border-radius: 2px;
  }

  .search {
    align-items: center;
    flex: 1;
    border-radius: 2px;

    [class*='input'] {
      flex: 1;
    }

    input {
      flex: 1;
      border-radius: 2px !important;
      border: 0 !important;
      padding: 12px !important;
      box-shadow: @ref-box-shadow !important;
    }
  }
}



