@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';

.dotsMenuPopup {

  [class="content"] {
    display: flex;
    flex-direction: column;
  }

  button + button {
    margin-top: 10px !important;
  }
}

.dotsMenuButton {
  width: 28px !important;
  height: 21px !important;
  padding: 0 !important;
  background: transparent !important;
  border-radius: 3px !important;
  margin-left: auto !important;
  z-index: 2 !important;

  &:hover,
  &:focus {
    background: @ref-color-lightBlue !important;

  }
}

.dotsActiveButton {
  text-align: left !important;
}
