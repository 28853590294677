@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../../site.variables';

.dataRow {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: 16px;

  & > [class^="field"] {
    margin-bottom: 0 !important;
  }

  label {
    width: 100%;
    display: block;
  }

  input,
  textarea {
    width: 100%;
    display: block;
  }

  [class*='button'] {
    width: fit-content;
  }
}
