@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';

.header {
  width: 100%;
  flex: 1 1 auto;
  position: fixed;
  top: 0;
  z-index: 1001;
  height: 50px;
  max-height: 50px;

  & > [class^="contentWrapper--"] {
    padding: 0 1em 0 64px;

    @media (min-width: @breakpoint-tablet) {
      padding: 0 50px 0 70px;
    }
  }

  &:before {
    content: '';
    width: calc(100vw + 100%);
    height: 100%;
    display: block;
    background: @ref-color-active;
    position: absolute;
    top: 0;
    right: calc(50% - 50vw);
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;

    .logo {
      color: @white;
      max-width: 105px;
      max-height: 30px;
      display: inline-block;
      width: 100%;
      position: relative;
    }

    .menu {
      margin: 0;
      min-height: auto;

      .item {
        cursor: pointer;
        color: @white;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: 0px;
        padding: 0 20px;
        margin: 0;
        border-left: 1px solid #A2A2A2;
        border-radius: inherit;

        @media (max-width: @breakpoint-tablet - 1) {
          border-left: none;
        }

        &:hover,
        &:focus {
          color: @white !important;
          text-decoration: underline;
        }
      }

      & > .item {
        @media (max-width: @breakpoint-tablet - 1) {
          display: none;
        }
      }


      & > .item:first-child {
        border-left: none;
      }

      .itemLogin {
        display: flex;

        .item {
          padding-right: 0;
        }
      }

    }
  }
}



