@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import 'common/components/site.variables';

.pageTitle {
  position: relative;
  color: @ref-color-blackLight;
  padding: 2rem 0;

  &:before {
    position: absolute;
    top: 0px;
    bottom: 0;
    left: calc(50% - 50vw);
    width: 100vw;
    height: 100%;
    display: block;
    content: " ";
    background: @ref-color-middleWhite;
  }

  h1.title {
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0;
    margin: 2rem 0 1rem;
    text-transform: uppercase;
  }

  .description {

  }

  .title,
  .description {
    position: relative;
    z-index: 1;
    color: inherit;
  }

}


