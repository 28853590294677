@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';

.headerWrapper {
  z-index: 110;
}

.headerWrapperInner {
  width: 100%;
  position: fixed;
  top: 0;
  background: @white;
  z-index: inherit;
  box-shadow: 0 3px 2px 0 rgba(50, 50, 50, 0.06);

  & > [class^="contentWrapper--"] {
    padding: 0 1em 0 64px;

    @media (min-width: @breakpoint-tablet) {
      padding: 0 50px 0 70px;
    }
  }
}

