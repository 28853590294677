@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';

.swiper-container {
  padding: 15px 0;
  position: relative;

  &:before {
    position: absolute;
    top: 0px;
    bottom: 0;
    left: calc(50% - 50vw);
    width: 100vw;
    height: 100%;
    display: block;
    content: " ";
    background: @light-grey;
  }


  .swiper {
    margin: -15px 0 0;
    padding-top: 15px;

    @media (min-width: 680px) {
      margin: -15px -10px 0;
    }

    .swiper-wrapper {
      display: flex;
      justify-content: stretch;
      margin-top: 18px;
    }

    .swiper-slide {
      width: 100%;
      height: auto;

      & > div {
        height: 100%;
      }

      @media (min-width: 680px) {
        width: calc((100% / 2) - 20px);
        margin: 0 10px;
      }

      @media (min-width: @breakpoint-tablet-horizontal) {
        width: calc((100% / 3) - 20px);
      }

      @media (min-width: @breakpoint-desktop-small) {
        width: calc((100% / 4) - 20px);
      }
    }


    .swiper-navigation {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;

      .swiper-pagination {
        position: relative;
        top: unset;
        left: unset;
        bottom: unset;
        width: auto;
        padding: 0 16px;
      }

      .swiper-pagination-bullet {
        border: 1px solid #000;
        background: transparent;
        opacity: 1;
        transition: opacity 300ms;

        &:hover {
          opacity: 0.2;
        }

        &-active {
          background-color: @ref-color-active;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: auto;
        height: auto;
        margin: 2px 0 0 0;
        line-height: 1;
        position: relative;
        color: @ref-color-active;

        left: initial;
        right: initial;
        top: initial;
        bottom: initial;

        &:after {
          font-size: 12px;

        }
      }
    }
  }


}

