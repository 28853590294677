@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../../site.variables';

.modelGridWrapper {
  padding: 20px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modelGridHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 10px 20px;
  width: calc(100% + 20px);
  margin: 0 -10px;
  z-index: 2;
  background-color: @white;
}

.modelGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
  gap: 20px;
}
