@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';


.headline {
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding-bottom: 10px;

  > button {
    white-space: nowrap;
    min-width: 92px;
  }

  > button:last-child {
    margin-left: auto;
  }
}

.secondary {
  .headline {
    border-bottom: none;

    .title {
      text-transform: none !important;
      font-size: 14px;
      letter-spacing: 0px;
      text-align: left;

      span {
        font-weight: 700;
      }
    }
  }
}

.title {
  margin: 0;
  padding: 0;
  text-transform: none;
  font-weight: 600;
  color: #333333;
}
