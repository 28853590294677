@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../../site.variables';

.languageSelector {

  &.ui.selection.dropdown {
    box-shadow: none;
    border: 0;
    min-height: inherit;
    background: @ref-color-active;
    padding: 0 20px !important;
    min-width: auto !important;
    width: auto;

    @media (max-width: 1199px) {
      padding-right: 0;
    }

    &:hover,
    &:active,
    &:focus {
      border-color: @ref-color-extraBlue;
      box-shadow: none;
    }

    .menu {
      left: auto;
      right: 0;
      width: auto;
      max-height: initial;
      border: 1px solid @ref-color-border;
      border-radius: 0;
      box-shadow: none;

      .item {
        font-weight: 600 !important;
        line-height: 2rem !important;
        letter-spacing: 0;
        text-transform: uppercase !important;
        color: @ref-color-greyDark !important;
        border-radius: 0;
        border-bottom: 6px solid;
        border-bottom-color: transparent;
        transition: border-bottom-color 300ms !important;
      }

      .active.item {
        background: transparent !important;
        color: @ref-color-active !important;
        border-bottom-color: @ref-color-active;
      }

      .item:hover {
        background: transparent !important;
        color: @ref-color-active !important;
        border-bottom-color: @ref-color-active;

      }
    }

    &:hover .menu {
      border-color: @ref-color-border;
      box-shadow: none;
    }

    .divider {
      .languageLabel {
        display: none;
      }
    }

    .text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      padding: 0;
    }

    .languageLabel {
      margin-left: 5px;
    }

    .languageFlag-wrapper {
      display: flex;
    }

    img {
      width: 30px;
      height: auto;
    }
  }

}
