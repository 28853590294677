@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import 'common/components/site.variables';

.modelCard {
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.154229);
  background-color: @white;
  display: flex;
  flex-direction: column;
  transition: 300ms;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
  }

  .modelImage {
    height: 150px !important;
    object-fit: cover;
  }

  .imagePlaceholder {
    height: 150px;
    background-color: @ref-color-lightGrey;
  }

  .modelContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px 10px 8px;
    color: @dark-grey;

    .modelHeadline {
      text-align: left;
      padding-left: 18px;
      position: relative;

      .metaStatus {
        display: block;
        position: absolute;
        left: 0;
        top: 6px;
        width: 10px;
        height: 10px;
        flex-basis: 10px;
        border-radius: 50%;
      }

      .modelTitle {
        margin: 0 0 5px 0;
        color: @dark-grey;
      }

      .modelDescription {
        color: #666666;
        font-size: 13.5px;
        font-weight: 400;
        line-height: 1.3;
      }
    }

    .modelBtnGroup {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .buttonIcon {
        margin: 0;
        padding: 0;

        border: none;
        box-shadow: none;
        background: unset;
        cursor: pointer;
        transition: 300ms;

        font-size: 15px;
        letter-spacing: 0px;
        line-height: 1;
        font-weight: 300;
        color: black;

        &:hover,
        &:focus {
          color: @red;
        }
      }

      .modelAuthor {
        margin-top: 0 !important;
        display: flex;
        align-items: flex-start;

        .modelAvatarLink {
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          border: none;
          box-shadow: none;
          max-width: 90px;

          color: @blue;
          text-align: left;

          font-size: 12px;
          font-weight: 400;
          line-height: 1;

          text-transform: unset;
          background: unset;
          transition: 300ms;

          &:hover,
          &:focus {
            color: @dark-grey;
          }
        }

        .modelMetaText {
          display: block;
          position: relative;
          color: @ref-color-blackLight;

          font-size: 12px;
          font-weight: 400;
          line-height: 1.5;

          text-align: left;
          padding-left: 8px;
          padding-right: 10px;
          margin-left: 6px;

          &:before {
            content: '';
            display: block;
            width: 1px;
            height: 80%;
            background-color: @ref-color-blackLight;
            position: absolute;
            top: 10%;
            left: 0;
          }
        }
      }

      .modelActions {
        margin-top: 14px;

        button:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
}



