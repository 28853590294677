@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
// Leaflet Map
.leaflet-control-container .leaflet-top,
.leaflet-control-container .leaflet-bottom {
  transform: translate3d(0px, 0px, 0px);
}

.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
}
