@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import "common/components/site.variables";

.list {
  font-size: 12px;
  font-weight: 400;
  max-height: 265px;
  min-height: 265px;
  margin-bottom: 30px !important;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: @light-grey;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: @blue;
    border-radius: 6px;
  }

  .item {
    padding: 0 5px 0 25px !important;
    margin: 0;
    position: relative;

    * > {
      z-index: 1;
    }
  }

  .checkboxBoundaryWrapper {
    margin: 2px 5px 0 0;
  }

  .title {
    height: 27px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid @border-grey;

    &:before,
    &:after {
      content: none;
      height: 26px;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:before {
      width: 100%;
      background-color: @light-grey;
    }

    &:after {
      width: 2px;
      background-color: @blue;
    }

    &:hover {
      &:before,
      &:after {
        content: '';
      }
    }

    &.titleSelected {
      font-weight: 800;
    }

  }

  .titleInner {
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 10px;
    height: 100%;

    & > [class*="checkbox"] {
      width: 15px;
      min-width: 15px;
      height: 15px;
      min-height: 15px;
      margin-right: 12px;
    }
  }

  .renameField {
    width: 100%;
    display: flex;
    align-items: stretch;

    input {
      display: flex;
      height: auto;
      border: none;
      border-radius: 6px;
      padding: 5px 1px;
      color: @dark-grey;
      line-height: 16px;
      background-color: @light-grey;
    }

    button {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: auto;
      color: @white;
      background-color: @blue;
      font-size: 12px;
      letter-spacing: 0;
      text-align: center;
      line-height: 19px;
      border: none;
      border-radius: 6px;

      &:hover {
        background-color: @hoverBlue;
      }
    }

  }

  .buttonsWrapper {
    display: flex;
    align-items: center;
  }

  .accordionContent {
    border-bottom: 1px solid @border-grey;

    .listObservations {
      padding: 2px !important;

      .checkboxObservation {
        min-height: 12px;
        min-width: 12px;
        line-height: 12px;
        max-height: 12px;
        margin-right: 10px;

        label:before {
          border-radius: 50%;
          width: 10px;
          height: 10px;
          background-color: @light-grey;
          box-shadow: none !important;
        }

        input:checked ~ label:after {
          content: "";
          display: block;
          background-color: @blue;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .observationItem {
        position: relative;
        width: 100%;
        margin: 0 18px 0 20px;
        display: flex;
        align-items: center;

        span {
          display: flex;
          align-items: center;
        }

        .dotsMenu {
          margin-left: auto !important;
        }

        .selected {
          background-color: @light-grey;
          text-shadow: none !important;
        }
      }

      [class*="item"] {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        padding: 0 !important;
        cursor: pointer;
        position: relative;

        &:before,
        &:after {
          visibility: visible;
          content: none;
          height: 100%;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
        }

        &:before {
          width: 100%;
          //background-color: @border-grey;
        }

        &:after {
          width: 2px;
          //background-color: @ref-color-blackLight;
        }

        &:hover {
          &:before,
          &:after {
            content: '';
          }
        }
      }

      [class*="item"]:not(:last-child) {
        .observationItem:before {
          position: absolute;
          content: '';
          left: 0;
          bottom: -8px;
          width: 100%;
          height: 1px;
          //background-color: @light-grey;
        }
      }
    }
  }

  [class*="disabled"],
  .disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }

  button.dotsMenu {
    margin-left: 0 !important;
    color: @dark-grey !important;
    transition: transform 300ms;

    &:hover,
    &:focus {
      background-color: transparent !important;
    }
  }
}
