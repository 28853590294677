@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import 'common/components/site.variables.less';

form {
  &.projectsFilterForm {
    background: @ref-color-lightGrey;
    height: 100%;
    padding: 0 25px;

    .titleWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
      padding-right: 25px;

      .title {
        margin: 0;
        opacity: 1;
        color: @ref-color-blackLight;
        font-size: 25px;
        font-weight: 400;
        line-height: 33px;
        text-transform: uppercase;
      }
    }

    div.field {
      margin-bottom: 18px !important;

      label.label {
        color: @ref-color-blackLight;
        font-size: 16px !important;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 21px !important;
        margin-bottom: 8px;
      }

      label.labelSmall {
        color: @ref-color-blackLight;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 18px;
        margin: 5px;
      }

      label.labelMap {
        display: flex;
        align-items: center;

        .checkbox {
          margin-left: 10px;
        }
      }

      .checkboxWrapper {
        display: flex;

        .count,
        .description {
          margin: 0 0 0 5px;
        }
      }

      .count span {
        color: @ref-color-red;
      }

      .checkboxWrapper:not(:last-child) {
        margin-bottom: 5px;
      }

      .checkbox {

        label {
          color: @ref-color-blackLight;
          font-size: 13.5px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 19px;

          &:before {
            top: 2px;
          }

          &:after,
          &:before {
            width: 15px;
            height: 15px;
          }

        }
      }

      .checkboxLabel {

        label {
          color: #333333;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 21px;
          margin-bottom: 8px;
          padding-left: 0;
          padding-right: 1.85714em;

          &:before {
            top: 2px;
          }

          &:after,
          &:before {
            width: 15px;
            height: 15px;
            left: unset;
            right: 0;
          }
        }

      }

      .checkboxLabel {
        &[class^="checked"] + [class^="mapWrapper--"] {
          background: red;

        }
      }

      .checkboxBoundary {
        min-width: 66px;
      }

      .radioWrapper {
        margin-bottom: 10px;
      }

      .radio {
        display: inline-flex;

        &:not(:last-child) {
          padding-right: 6px;
          border-right: 1px solid @ref-color-red;
        }

        &:not(:first-child) {
          padding-left: 6px;
        }

        label {
          color: @ref-color-red;
          font-size: 13.5px;
          font-weight: 400;
          letter-spacing: 0px;
          padding: 0;

          &:after,
          &:before {
            content: none !important;
          }
        }

        input:checked ~ label {
          text-decoration: underline;
        }

      }

      .dropdown {
        a {
          color: @blue;
        }
      }

      .sliderWrapper {
        margin-top: 36px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .sliderLabel {
          color: @ref-color-blackLight;
          font-size: 11px;
          font-weight: 400;
          letter-spacing: 0px;
          display: inline-block;
        }
      }

      .metaStatus {
        display: inline-block;
        width: 10px;
        height: 10px;
        flex-basis: 10px;
        border-radius: 50%;
        margin-left: 5px;
      }
    }

    .mapWrapper {
      width: 100%;
      height: 200px;
      overflow: hidden;
      position: relative;
    }

  }
}
