@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import 'common/components/site.variables';


.articleItem {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  color: @ref-color-blackLight;
  background-color: #EEEEEE;
  height: 100%;


  .articleImage {
    width: auto;
    max-height: 175px;
    height: 100%;
    display: flex;
    background-color: @ref-color-blue;
    object-fit: contain;
  }

  .articleInner {
    padding: 0.5rem 22px 1rem;
    word-wrap: break-word;
    flex: inherit;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: stretch;
  }

  .articleTitle {
    font-size: 22px;
    line-height: 1.5;
    font-weight: 500;
    letter-spacing: -0.6px;
    color: @ref-color-blackLight;
    text-transform: none;
    text-decoration: none;
  }

  .articleDescription {
    padding-bottom: 1rem;
    font-size: 0.9rem;
    line-height: 1.4;
    margin-bottom: 0px;
    word-break: break-word;
    word-wrap: break-word;
    color: @dark-grey;
    font-family: "Open Sans TUD", "Open Sans", OpenSans, Helvetica, Arial, sans-serif;
  }

  .buttonWrapper {
    border-top: 1px solid @white;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: auto;
    padding: 10px 0 0 0;

    .buttonLink {
      cursor: pointer;
      transition: color 300ms;
      background: transparent;
      color: @ref-color-red;
      font-size: 1.05rem;
      font-weight: 400;
      line-height: 1.7;
      margin: 0;
      text-align: inherit;

      &:hover,
      &:focus {
        color: @ref-color-active;
      }
    }

    .button {
      cursor: pointer;
      font-size: 1.05rem;
      font-weight: 400;
      line-height: 1.7;
      margin: 0;
      background: @blue;
      color: @white;

      &:hover,
      &:focus {
        background: @ref-color-active;
      }
    }

    .button,
    .buttonLink {
      width: 45%;
      padding: 8px 20px;
      text-align: center;
    }
  }
}




