@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;

.js-plotly-plot {
  &.plotlyContainer {
    display: flex;
    width: 100%;
    flex-grow: 5;
  }

  &.plotlyContainerModal {
    width: 90%;
    height: 90%;
  }

  .modebar-container {
    .modebar {
      display: flex;
      flex-direction: column;
      left: -5px;
      top: 10px;
      right: initial;
    }

    .modebar-group {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }
}
