@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../../../site.variables';

.legend {
  color: #555;
  width: auto;
  background: rgba(255, 255, 255, 0.6);
}

.legend_info {
  padding: 6px 8px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 25px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: center;
}


.legend_inner_vertical,
.legend_inner_horizontal {
  position: relative;
  overflow: visible;
}

.legend_inner_vertical {
  display: flex;
  width: 100%;
  justify-content: end;

  .legend_line {
    height: 320px;
    width: 20px;
  }

  .legend_indicator {
    top: -12px;
    right: 25px;

    &:before {
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 18px solid @black;
      right: -16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.legend_inner_horizontal {
  .legend_line {
    width: 320px;
    height: 20px;
  }

  .legend_indicator {
    top: -26px;
    left: 0;

    &:before {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 18px solid @black;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

}

.legend_line {
  border-radius: 10px;
}

.legend_indicator {
  position: absolute;
  background: @black;
  color: @white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 7px;
  min-width: 30px;
  height: 22px;
  transition: all 300ms;
  box-shadow: @ref-box-shadow;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
  }
}





