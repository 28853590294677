@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
.rc-slider {

  .rc-slider-handle {
    background: #fff linear-gradient(transparent, rgba(0, 0, 0, .05));
    margin-top: -4px;
    opacity: 1;
    border: none;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15), inset 0 0 0 1px rgba(34, 36, 38, 0.15);

    &:hover,
    &-dragging {
      box-shadow: 0 0 5px #57c5f7 !important;
      cursor: grabbing;
    }
  }

  .rc-slider-rail {
    background-color: #fff !important;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3) !important;
  }

  .rc-slider-rail,
  .rc-slider-step,
  .rc-slider-track {
    height: 6px;
    border-radius: 0px;
  }

  .rc-slider-track {
    background-color: #1481b8 !important;
    border-radius: 0px;
  }

  &.rc-slider-disabled {
    opacity: 0.5;
  }
}
