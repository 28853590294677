@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import "../../../../../common/components/site.variables";


.zoneList {
  .header {
    display: flex;
    color: @black;
    margin-left: 35px;

    & *:first-child {
      display: block;
      width: 52%;
    }
  }
}

.zoneListItem {
  display: flex;
  gap: 10px;
  width: 100%;

  .zoneListItemName {
    width: 55%;

    input {
      box-shadow: none;
      background-color: @light-grey;
    }

    input:focus-visible {
      background-color: @white;
    }
  }

  .zoneListItemValue {
    transition: opacity 300ms;
    width: 35%;

    input {
      text-align: end;
      background-color: @light-grey
    }
  }

  .zoneListItemButton {
    transition: opacity 300ms;

    width: 10%;

    [class*="button"] {
      min-width: unset;
      height: 100%;
    }

    button.dotsMenu {
      color: @black !important;
      transition: transform 300ms;

      &:hover,
      &:focus {
        background-color: transparent !important;
        transform: scale(1.2);
      }
    }
  }

  &.zoneListItemDisabled {

    .zoneListItemName,
    .zoneListItemValue {
      opacity: 0.5;
      pointer-events: none;
    }
  }

}




