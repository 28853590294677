@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../../site.variables';

.modalContent {
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.modalActions {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;

  &:hover,
  &:focus {
    span {
      background: @ref-color-grey;
    }
  }
}

.modalButton {
  display: flex;
  background: transparent;
  border: 0;
  width: 24px;
  height: 20px;
  position: relative;
  margin: 0 0 0 auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: @ref-color-burger;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    transform: rotate(45deg);
    top: 8px;
    left: 1px;
  }

  span:nth-child(2) {
    transform: rotate(-45deg);
    top: 16px;
    left: 4px;
    transform-origin: left center;
  }
}
