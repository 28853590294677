@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import "common/components/site.variables";


.header {
  display: flex;
  gap: 12px;
  margin: 0 0 0 25px;
  padding: 0 0 10px 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid @border-grey;

  span {
    font-size: 16px;
    font-weight: 500;
  }

  .search {
    width: 100%;

    * {
      width: inherit;
    }

    input {
      border: 0 !important;
      padding: 5px !important;
      border-radius: 6px !important;
      background-color: @light-grey;
      box-shadow: none !important;
    }

    [class*="results"] {
      display: none !important;
    }
  }
}

