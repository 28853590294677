@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
.leaflet-text-icon {
  background-color: black;
  opacity: 0.5 !important;
  border: none !important;
  font-size: 20px !important;
  margin: -10px auto auto -10px !important;
  padding: 0 !important;
  font-weight: bold !important;
  color: white !important;
  align-content: center !important;
  text-align: center !important;
  vertical-align: center !important;
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
}
