@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import "../../../../../../common/components/site.variables";

table.table {

  th, td {
    box-sizing: border-box;
    padding: 5px 20px !important;
    min-height: 34px;
  }

  input[type="number"] {
    width: auto;
  }

  .editableCell {
    width: inherit;
    display: flex;
    align-items: stretch;
  }

  .actions {
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: flex-end;

    button {
      padding: 0 !important;
      background: transparent;
      color: @black;
      transition: transform 300ms;

      &:hover,
      &:focus {
        color: @black;
        background-color: transparent !important;
        transform: scale(1.2);
      }
    }
  }
}
