@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import "common/components/site.variables";

.assetTable {
  padding: 10px 10px 20px 10px;
  width: 100%;

  .table.table {

    thead {
      border-bottom-color: @border-grey;

      &:before {
        background: @border-grey;
      }
    }


    tbody tr td,
    thead tr th {
      padding: 6px 0;
    }

    tbody tr td:not(:last-child),
    thead tr th:not(:last-child) {
      padding-right: 12px;
    }

    tbody tr td:first-child {
      color: @blue;
    }

    thead tr th {
      font-size: 16px;
    }

    tbody tr {
      cursor: pointer;

      td {
        font-size: 13.5px;
        border-bottom: 1px solid @border-grey;
        line-height: 12px;
      }

      &.selected,
      &:hover {
        background-color: @light-grey;
        color: @dark-grey;

        td:not(:last-child) {
          //text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}

