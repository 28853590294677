@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';

.backToTopButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  background-color: @ref-color-darkBlue;
  border-radius: 0;
  border: 0;
  opacity: .8;
  margin: 15px 0 0 15px;
  padding: 0;
  text-align: center;
  color: #fff;
  height: 3rem;
  width: 3rem;
  transform: none;
  transition: background-color 300ms;

  @media (min-width: @breakpoint-mobile-horizontal) {
    position: absolute;
    top: unset;
    bottom: 15px;
    right: 15px;
    margin: 0;
  }

  @media (min-width: @breakpoint-tablet) {
    right: 60px;
  }

  &:hover {
    background-color: @ref-color-lightBlue;
  }
}

.arrowUp {
  border: solid @white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;

  &:first-child {
    transform: translateY(3px) rotate(-135deg);
  }

  &:not(:first-child) {
    transform: translateY(-3px) rotate(-135deg);
  }
}

.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
