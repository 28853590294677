@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../../site.variables';

.dataGrid {
  display: grid;
  grid-template-columns: minmax(280px, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  align-items: center;

  & > [class^="field"] {
    margin-bottom: 0 !important;
  }

  @media (min-width: @breakpoint-mobile-horizontal) {
    grid-template-columns: minmax(320px, 1fr);
  }
}

.dataGridMulti_2 {
  [class^="field"] {
    @media (min-width: @breakpoint-tablet) {
      margin: 0 !important;
    }
  }
}

.dataGridMulti_4 {
  @media (min-width: @breakpoint-tablet) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
}

.generateMultiColumns(@maxColumns) when (@maxColumns > 1) {
  .dataGridMulti_@{maxColumns} {
    @media (min-width: @breakpoint-desktop-small) {
      grid-template-columns: repeat(@maxColumns, 1fr);
      grid-column-gap: 20px;
    }
  }

  .generateMultiColumns(@maxColumns - 1);
}

.generateMultiColumns(12); // Adjust the value based on your maximum columns
