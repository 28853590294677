@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../../site.variables';

.sortDropdownContainer {
  position: relative;
  display: inline-block;

  .sortDropdown {

    &.ui.selection.dropdown {
      display: flex;
      align-items: flex-end;
      padding: 0 0 0 24px !important;
      min-height: initial !important;
      box-shadow: none !important;
      border: none !important;
      z-index: 1;
      background: unset;
      min-width: initial !important;
      width: auto;

      .divider,
      & > .text:not(.default) {
        color: rgba(221, 39, 39, 1);
        font-size: 13.5px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 19px;
      }

      &.active:hover {
        border: none;
        box-shadow: none;
      }

      .icon {
        position: absolute;
        left: 0;
        bottom: 2px;
      }

      .menu {
        position: absolute;
        left: auto;
        right: 0;
        width: auto !important;
        margin-top: 8px !important;
        min-width: 100px !important;
        max-height: calc(100vh - 200px);
        background-color: @white;

        //border: 0 !important;
        border-radius: 0 !important;
        box-shadow: unset !important;
        border: 1px solid @ref-color-border !important;


        & > .item {
          font-size: 15px;
          letter-spacing: 0;
          line-height: 1.333;
          color: @ref-color-greyDark;
          text-transform: uppercase;
          font-weight: 400;

          &::before {
            transition: background-color 300ms;
            content: '';
            position: absolute;
            width: 100%;
            bottom: 0;
            top: auto;
            right: 0;
            left: 0;
            height: 6px;
            z-index: 1;
            background-color: transparent;
          }
        }

        & > .item:not(:last-child) {
          border-bottom: 1px solid @ref-color-border;
        }

        & > .item:hover {
          background: transparent;
          color: @ref-color-active;

          &::before {
            background-color: @menu-color-hoverBorder;
          }
        }

        .selected.item {
          background: transparent;
          color: @ref-color-active;

          &::before {
            background-color: @ref-color-active;
          }
        }

        .text {
          white-space: nowrap;
        }
      }

    }
  }
}


