@orange: #f9b232;
@transparent: transparent;
@blue: #009fe3;
@white: #ffffff;
@red: #db2828;
@grey: grey;
@dark-grey: #333333;
@light-grey: #f5f5f7;
@border-grey: #d8d8d8;
@disabled: #bababa;

@input-border-color: rgba(34, 36, 38, 0.15);
@border-radius: 6px;
@box-shadow: 0px 0px 10px 0.5px @border-grey;


@responsiveWidth: 1200px;
@responsiveXsMaxWidth: 576px;
@responsiveSmMaxWidth: 768px;
@responsiveMdMaxWidth: 992px;
@import '../site.variables';


.uploadFileWrappe {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.uploadFile {
  background: @white;
  padding: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;

  h2 {
    margin-bottom: 22px;
  }
}

.uploadContent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fileLabel {
  cursor: pointer;
  position: relative;
  padding: 4px 10px;
  background-color: @blue;
  color: @white;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 2px;


  &:hover {
    background-color: #6C84A6;
    text-decoration: underline;
  }

}

.fileInput {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  opacity: 0;
}

.description {
  color: @ref-color-blackLight;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  line-height: 25px;

  padding: 22px 0;
  margin: 0;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  button {
    padding: 4px 24px !important;
  }

  button:not(:last-child) {
    margin-right: 10px;
  }
}
